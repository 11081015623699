import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useActionData,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { FieldsAndResults } from "../../../../../../API/fields";
import MessageFlash, {
  MessageLevel,
} from "../../../../../../Component/message-flash/MessageFlash";
import FieldPropertiesDialog from "../../components/FieldProperties/FieldPropertiesDialog";

export default function EditFieldProperties(): React.ReactNode | null {
  const { fields } = useRouteLoaderData("customer") as FieldsAndResults;
  const { fieldId, customerId } = useParams();
  const navigate = useNavigate();
  const wasSuccessful = useActionData() === "success";

  const close = () => navigate(`/customer/${customerId}/field/${fieldId}`);

  useEffect(() => {
    if (wasSuccessful) {
      setTimeout(close, 1000);
    }
  });

  const field = fields.features.find(
    (feature) => `${feature.properties.id}` === fieldId,
  )?.properties;

  if (!field) {
    return null;
  }

  const doNothing = () => {
    // do nothing
  };
  return (
    <FieldPropertiesDialog
      open={true}
      onSubmit={doNothing}
      onClose={close}
      existingFieldNames={fields.features
        .map((feature) => feature.properties.name)
        .filter((name) => name !== field.name)}
      message={<PropertiesUpdatedMessage />}
      defaultData={{
        crop: field.crop || "",
        name: field.name,
        plantDistance: field.planting_distance_meters
          ? field.planting_distance_meters * 100
          : 0,
        plantingDate: field.plant_date || "",
        remarks: field.remark || "",
        variety: field.variety || "",
      }}
    />
  );
}

export function PropertiesUpdatedMessage(): React.ReactNode | null {
  const status = useActionData() as MessageLevel | null;
  const { t } = useTranslation();

  if (!status) {
    return null;
  }

  const message = {
    [MessageLevel.Success]: t("field.message success"),
    [MessageLevel.Error]: t("field.message something went wrong"),
    [MessageLevel.Info]: null,
    [MessageLevel.Warning]: null,
  }[status];

  return <MessageFlash message={message} level={status} />;
}
