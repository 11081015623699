import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../API/auth";

export default function Home(): React.ReactNode {
  const auth = useAuth();

  if (!auth.accessToken) {
    return <Navigate to="/login" />;
  }

  const customerId = auth.accessTokenPayload?.customer_id;
  if (customerId) {
    return <Navigate to={`/customer/${customerId}`} />;
  }
  return <Navigate to="/customer" />;
}
