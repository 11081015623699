import { CutoffRange } from "./Taskmap";
import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./TaskmapWidget.module.css";
import {
  VictoryAxis,
  VictoryChart,
  VictoryHistogram,
  VictoryTooltip,
} from "victory";

export type TaskmapHistogramProps = {
  header: string;
  sortedData: number[];
  cutoff: CutoffRange;
  setCutoff: (cutoff: CutoffRange) => void;
};

export default function TaskmapHistogram({
  header,
  sortedData,
  cutoff,
  setCutoff,
}: TaskmapHistogramProps): React.ReactNode {
  const { t } = useTranslation();

  const maxValue = sortedData[sortedData.length - 1];
  const bins =
    maxValue > 10
      ? 15
      : [...Array(Math.floor(maxValue) + 1).keys()].map((index) => index + 0.5);

  return (
    <div className={classes.histogram}>
      <VictoryChart height={200} animate={false}>
        <VictoryHistogram
          style={{
            data: {
              fill: ({ datum }) => {
                const datumValue = datum.x | 0;
                return datumValue >= cutoff.min && datumValue <= cutoff.max
                  ? "var(--color-taskmap-enabled)"
                  : "var(--color-taskmap-disabled)";
              },
            },
          }}
          events={[
            {
              target: "data",
              eventHandlers: {
                onClick: (_, { datum }) => {
                  const datumValue = datum.x | 0;
                  if (
                    Math.abs(datumValue - cutoff.max) >
                    Math.abs(datumValue - cutoff.min)
                  ) {
                    setCutoff({ min: datumValue, max: cutoff.max });
                  } else {
                    setCutoff({ min: cutoff.min, max: datumValue });
                  }
                },
              },
            },
          ]}
          data={sortedData.map((value) => ({ x: value }))}
          bins={bins}
          labels={({ datum }) =>
            t("tooltip.{{n}} trees", { n: datum.y | 0 }) as string
          }
          labelComponent={<VictoryTooltip />}
        />
        <VictoryAxis dependentAxis fixLabelOverlap={true} />
        <VictoryAxis
          dependentAxis={false}
          fixLabelOverlap={true}
          label={header}
        />
      </VictoryChart>
    </div>
  );
}
