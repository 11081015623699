import React, { FunctionComponent } from "react";
import { NavLink, useMatches, type Params } from "react-router-dom";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./breadcrumbs.module.css";
import classNames from "classnames";

export type BreadcrumbProps = {
  mobileView: boolean;
};

function Breadcrumbs({ mobileView = false }: BreadcrumbProps): React.ReactNode {
  const matches = useMatches();
  const { t } = useTranslation();

  return (
    <MuiBreadcrumbs
      className={classNames({
        [styles.breadcrumbs]: true,
        [styles.breadcrumbsMobile]: mobileView,
      })}
    >
      <NavLink to="/">{t("crumbs.Home")}</NavLink>
      {matches.map((match) => {
        const text = generateCrumbText(match);
        return (
          text && (
            <NavLink key={match.pathname} to={match.pathname}>
              {text}
            </NavLink>
          )
        );
      })}
    </MuiBreadcrumbs>
  );
}

export type DocumentTitleProps = {
  suffix: string;
};

export function generateCrumbText(match: {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: unknown;
}): React.ReactNode | null {
  if (!match.handle || !(match.handle instanceof Object)) {
    return null;
  }
  if ("Crumb" in match.handle && match.handle.Crumb instanceof Function) {
    const Crumb = match.handle.Crumb as FunctionComponent<CrumbProps>;
    return <Crumb key={match.id} match={match} />;
  }
  return null;
}

export type CrumbProps<T extends string = string> = {
  match: {
    id: string;
    pathname: string;
    params: Params<T>;
    data: unknown;
    handle: unknown;
  };
};

export type CrumbType = FunctionComponent<CrumbProps>;

export default Breadcrumbs;
