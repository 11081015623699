import { RouteObject } from "react-router-dom";
import { updateFieldProps } from "../../../../../../API/fields";
import EditFieldProperties from "./EditFieldProperties";

const routes: RouteObject = {
  path: "edit",
  action: updateFieldProps,
  Component: EditFieldProperties,
};

export default routes;
