import React from "react";
import { Alert } from "@mui/material";

export enum MessageLevel {
  Info = "info",
  Warning = "warning",
  Error = "error",
  Success = "success",
}

export type MessageFlashProps = {
  message: string | null;
  level: MessageLevel;
};

export default function MessageFlash({
  message,
  level,
}: MessageFlashProps): React.ReactNode | null {
  if (!message) {
    return null;
  }

  return <Alert severity={level}>{message}</Alert>;
}
