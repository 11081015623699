import { Autocomplete, TextField } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerList } from "../../../API/customer";
import Button from "../../../Component/Button/Button";
import DisplayDate from "../../../Component/DisplayDate/DisplayDate";
import styles from "./CustomerSelect.module.css";

export type OnCustomerSelect = (customerId: number) => Promise<void>;

type CustomerSelectFormProps = {
  onCustomerSelect: OnCustomerSelect;
  customers: CustomerList;
};

export default function CustomerSelectForm({
  onCustomerSelect,
  customers,
}: CustomerSelectFormProps): React.ReactNode {
  const { t } = useTranslation();
  const [customerId, setSelectCustomerId] = useState<number | undefined>(
    undefined,
  );

  return (
    <div>
      <h1>{t("label.select.customer")}</h1>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (customerId) {
            onCustomerSelect(customerId);
          }
        }}
      >
        <fieldset className="boxed">
          <div className="vspace">
            <Autocomplete
              options={customers}
              getOptionLabel={(customer) => customer.name}
              renderOption={({ className, ...props }, customer) => (
                <li
                  {...props}
                  className={classNames(className, styles.option)}
                  key={customer.id}
                >
                  <strong className={styles.name}>{customer.name}</strong>
                  <span className={styles.date}>
                    <DisplayDate date={customer.created_at} />
                  </span>
                </li>
              )}
              fullWidth={true}
              onChange={(_, customer) => setSelectCustomerId(customer?.id)}
              renderInput={(params) => (
                <TextField {...params} label={t("label.customer.name")} />
              )}
            />
          </div>
          <div className="vspace">
            <Button
              disabled={!customerId}
              type="submit"
              size="large"
              variant="contained"
              fullWidth
              disableElevation
            >
              {t("button.Select")}
            </Button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
