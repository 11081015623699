import { useAuth } from "./auth";
import { LoaderFunctionArgs, Params } from "react-router-dom";
import { getUrl } from "./AureaApi";

export type Period = {
  start: `${number}-${number}-${number}T00:00:00Z` | `${string}T00:00:00Z`;
  end: `${number}-${number}-${number}T00:00:00Z` | `${string}T00:00:00Z`;
};

type AureaFlight = {
  type: "aurea_drone_flight";
  availability_periods: Period[];
};

type OwnFlight = {
  type: "own_drone";
};

export type OrderLine = {
  id?: string;
  product_id: string;
  field_id: number;
  order_comments: string;
  imagery_params: AureaFlight | OwnFlight;
};

export type Order = {
  id: string;
  created_at: `${number}-${number}-${number}`;
  updated_at: `${number}-${number}-${number}`;
  ordered_at: `${number}-${number}-${number}`;
  customer_id: number;
  current_delivery_status: string;
  current_payment_status: string;
  current_admin_status: string;
  current_creation_status: string;
  ordered_by: string;
  lines: OrderLine[];
};
type GetCustomerOrdersParams = LoaderFunctionArgs & {
  params: Params<"customerId">;
};

export default async function getCustomerOrders({
  params: { customerId },
}: GetCustomerOrdersParams): Promise<null | Order[]> {
  const token = await useAuth.getState().getToken();
  if (!token) {
    return null;
  }

  const orders = await fetch(getUrl(`/v1/customer/${customerId}/order`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return orders.json();
}

export async function submitCustomerOrderLinesWithOrder(
  customerId: number,
  lines: OrderLine[],
): Promise<Response | null> {
  const token = await useAuth.getState().getToken();
  if (!token || !lines) {
    return null;
  }

  const newOrderResponse = await fetch(
    getUrl(`/v1/customer/${customerId}/order`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (newOrderResponse.status > 299) {
    throw new Error("Could not create order");
  }

  const newOrder = (await newOrderResponse.json()) as Order;

  await Promise.all(
    lines.map((line) =>
      fetch(getUrl(`/v1/order/${newOrder.id}/line`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(line),
      }),
    ),
  );

  return await fetch(getUrl(`/v1/order/${newOrder.id}/`), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PATCH",
    body: JSON.stringify({
      current_creation_status: "done",
    }),
  });
}
