import React from "react";
import {
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  LoaderFunction,
} from "react-router-dom";
import getCustomerOrders from "../../../../API/orders";
import confirm from "./confirm";
import newOrder from "./new";
import OrderPageCrumb from "./OrderPageCrumb";

const routes: RouteObject = {
  path: "order",
  loader: getCustomerOrders as LoaderFunction,
  handle: {
    Crumb: OrderPageCrumb,
  },
  Component: Outlet,
  children: [
    {
      index: true,
      Component(): React.ReactNode {
        const { search } = useLocation();
        return React.createElement(Navigate, { to: `new${search}` });
      },
    },
    newOrder,
    confirm,
  ],
};

export default routes;
