import { RouteObject } from "react-router-dom";
import getResultSetLayer, {
  GetResultSetLayerParams,
} from "../../../../../../API/result_set_layer";
import download from "./download";
import ResultSetCrumb from "./ResultSetCrumb";
import ResultSetPage from "./ResultSetPage";

const routes: RouteObject = {
  id: "resultSet",
  path: "result-set/:resultSetId",
  loader: (params) => ({
    geojson: getResultSetLayer(params as GetResultSetLayerParams),
  }),
  handle: {
    Crumb: ResultSetCrumb,
  },
  children: [download],
  Component: ResultSetPage,
};

export default routes;
