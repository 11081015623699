import React from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

type CollapsibleProps = {
  children: React.ReactNode;
  position?: "left" | "right";
  label: {
    open: string;
    close: string;
  };
  className?: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

type ToggleButtonProps = {
  className: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  label: {
    open: string;
    close: string;
  };
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  className,
  isOpen,
  onOpenChange,
  handleKeyDown,
  label,
}) => (
  <button
    className={className}
    onClick={() => onOpenChange(!isOpen)}
    onKeyDown={handleKeyDown}
    aria-expanded={isOpen}
    aria-label={isOpen ? label.close : label.open}
  >
    {isOpen ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
  </button>
);

export default function Collapsible({
  children,
  position = "left",
  label,
  className = "",
  isOpen,
  onOpenChange,
}: CollapsibleProps): React.ReactNode {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onOpenChange(!isOpen);
    }
  };

  return (
    <div className="relative flex">
      {position === "left" && (
        <ToggleButton
          className={className}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          handleKeyDown={handleKeyDown}
          label={label}
        />
      )}
      {isOpen && children}
      {position === "right" && (
        <ToggleButton
          className={className}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          handleKeyDown={handleKeyDown}
          label={label}
        />
      )}
    </div>
  );
}
