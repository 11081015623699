import React, { useEffect } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { FieldsAndResults } from "../../../../../API/fields";
import useOrder from "../useOrder";
import NewOrderForm from "./NewOrderForm";

export default function NewOrderPage(): React.ReactNode {
  const { fields } = useRouteLoaderData("customer") as FieldsAndResults;
  const [searchParams] = useSearchParams();
  const order = useOrder();
  const navigate = useNavigate();

  useEffect(() => {
    if (!order.fieldIds.length) {
      order.setFieldIds(
        searchParams
          .getAll("field")
          .filter((urlField) =>
            fields.features.some(
              (field) => `${field.properties.id}` === urlField,
            ),
          ),
      );
    }
  }, [searchParams]);

  const allFields = fields.features.map((feature) => feature.properties);

  return (
    <NewOrderForm
      fields={allFields}
      onSubmit={() => navigate("../confirm", { relative: "route" })}
      initialFields={searchParams.getAll("field")}
    />
  );
}
