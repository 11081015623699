import React from "react";
import RegisterAccountForm from "./RegisterAccountForm";
import { Splash } from "../../../Layout";

export default function RegisterAccount(): React.ReactNode | null {
  return (
    <Splash>
      <RegisterAccountForm />
    </Splash>
  );
}
