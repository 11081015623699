import { RouteObject } from "react-router-dom";
import VerifyEmail from "./VerifyEmail";
import VerifyEmailCrumb from "./VerifyEmailCrumb";

const routes: RouteObject = {
  id: "verify-email",
  path: "/verify-email",
  Component: VerifyEmail,
  handle: {
    Crumb: VerifyEmailCrumb,
  },
};

export default routes;
