import React from "react";
import FieldOverviewPage from "./FieldOverviewPage";
import {
  Outlet,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { FieldResultMap } from "../../routes/customer/_customer_id/field/components/FieldSelect/FieldSelect";
import { FieldsAndResults } from "../../API/fields";
import useShowTrees from "../../routes/customer/_customer_id/field/_field_id/useShowTrees";
import { LoadingProvider } from "../LoadingSpinner/LoadingContext";

export default function FieldPage(): React.ReactNode {
  const [showTrees] = useShowTrees();
  const showTreesStatus = `?showTrees=${showTrees}`;

  const { fieldId, resultSetId: selectedResultSetId, customerId } = useParams();
  const {
    fields: geojson,
    resultSets,
    tilings,
  } = useRouteLoaderData("customer") as FieldsAndResults;
  const resultSetMap: FieldResultMap = resultSets.reduce(
    (map: FieldResultMap, resultSet) => {
      if (!map[resultSet.field_id]) {
        map[resultSet.field_id] = [];
      }
      map[resultSet.field_id].push({
        product: resultSet.product_sku,
        date: resultSet.detection_dates[0] as `${number}-${number}-${number}`,
        result_set_id: resultSet.id,
        external_visibility: resultSet.external_visibility,
      });
      return map;
    },
    {},
  );
  const navigate = useNavigate();
  return (
    <LoadingProvider>
      <FieldOverviewPage
        geojson={geojson}
        tilings={tilings}
        fieldResultSetsMap={resultSetMap}
        selectedFieldId={toInt(fieldId)}
        selectedResultSetId={selectedResultSetId}
        onSelectResultSet={(resultSetId, fieldId, customerId) =>
          navigate(
            resultSetId
              ? `/customer/${customerId}/field/${fieldId}/result-set/${resultSetId}${showTreesStatus}`
              : `/customer/${customerId}/field/${fieldId}${showTreesStatus}`,
          )
        }
        onSelectField={(fieldId, customerId) =>
          navigate(`/customer/${customerId}/field/${fieldId}`)
        }
        onCreateTaskMap={(resultSetId: string, fieldId, customerId) =>
          navigate(
            `/customer/${customerId}/field/${fieldId}/taskmap/${resultSetId}`,
          )
        }
        onDownloadResult={(resultSetId: string) => {
          navigate(
            `/customer/${customerId}/field/${fieldId}/result-set/${resultSetId}/download`,
          );
        }}
        onOrderProduct={(product, fieldId, customerId) =>
          navigate(
            `/customer/${customerId}/order/new?field=${fieldId}&product=${product}${showTreesStatus}`,
          )
        }
      >
        <Outlet />
      </FieldOverviewPage>
    </LoadingProvider>
  );
}

export function toInt(input: string | undefined): number | undefined {
  const parsed = parseInt(input || "undefined", 10);
  return Number.isNaN(parsed) ? undefined : parsed;
}
