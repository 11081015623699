import { RouteObject } from "react-router-dom";
import RegisterAccount from "./RegisterAccount";
import RegisterAccountCrumb from "./RegisterAccountCrumb";
import { actionRegisterAccount } from "./signup";

const routes: RouteObject = {
  id: "register-account",
  path: "register",
  Component: RegisterAccount,
  action: actionRegisterAccount,
  handle: {
    Crumb: RegisterAccountCrumb,
  },
};

export default routes;
