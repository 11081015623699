import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../API/auth";
import { sendResetPasswordEmail } from "../../../API/password_reset";
import { Splash } from "../../../Layout";
import ForgotPasswordForm, {
  submitPasswordResetResult,
} from "./ForgotPasswordForm";

const messageMap: { [status: number]: submitPasswordResetResult } = {
  204: "success",
  422: "invalid",
};

export default function ForgotPassword(): React.ReactNode {
  const auth = useAuth();
  const [message, setMessage] = useState<submitPasswordResetResult>(null);
  const { i18n } = useTranslation();

  if (auth.username) {
    return <Navigate to="/" />;
  }

  return (
    <Splash>
      <ForgotPasswordForm
        onSubmitPasswordReset={async ({ email }) => {
          const response = await sendResetPasswordEmail(email, i18n.language);

          setMessage(messageMap[response.status] || "error");
        }}
        message={message}
      />
    </Splash>
  );
}
