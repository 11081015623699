import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../API/auth";

export default function RequireLogin(): React.ReactNode {
  const auth = useAuth();
  if (!auth.accessToken) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}
