import React from "react";
import I18nProvider from "./i18n/I18nProvider";
import { RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Layout from "./Layout";
import routes from "./routes";
import enhanceRouter from "./enhanceRouter";

export const router = enhanceRouter(createBrowserRouter([routes]));

function App() {
  return (
    <I18nProvider>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Layout>
          <RouterProvider router={router} />
        </Layout>
      </LocalizationProvider>
    </I18nProvider>
  );
}

export default App;
