import { Grid, Input, Slider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./TaskmapWidget.module.css";
import { CutoffRange } from "./Taskmap";

export type TaskMapSliderProps = {
  cutoff: CutoffRange;
  setCutoff: (cutoff: CutoffRange) => void;
  sortedData: number[];
};

function getAffectedTrees(sortedData: number[], cutoff: CutoffRange): number {
  const minIndex = sortedData.findIndex((item) => item >= cutoff.min);
  const maxIndex = sortedData.findLastIndex((item) => item <= cutoff.max);
  return (maxIndex - minIndex + 1) / sortedData.length;
}

export default function TaskMapSlider({
  cutoff,
  setCutoff,
  sortedData,
}: TaskMapSliderProps): React.ReactNode {
  const { t, i18n } = useTranslation();

  const formatter = Intl.NumberFormat(i18n.language, {
    style: "percent",
  });

  const minDataValue = Math.floor(sortedData[0]);
  const maxDataValue = Math.ceil(sortedData[sortedData.length - 1]);

  return (
    <div data-testid="taskMapSlider">
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Input
            className={styles.numberInput}
            value={cutoff.min}
            size="small"
            onChange={(event) => {
              const newCutoff = {
                min: Math.min(Number(event.target.value), cutoff.max),
                max: Math.max(Number(event.target.value), cutoff.max),
              };
              setCutoff(newCutoff);
            }}
            inputProps={{
              min: minDataValue,
              max: maxDataValue,
              type: "number",
            }}
          />
        </Grid>
        <Grid item xs>
          <Slider
            value={[cutoff.min, cutoff.max]}
            onChange={(_, newValue) => {
              setCutoff({
                min: (newValue as [number, number])[0],
                max: (newValue as [number, number])[1],
              });
            }}
            valueLabelDisplay="auto"
            getAriaValueText={String}
            min={minDataValue}
            max={maxDataValue}
            step={1}
          />
        </Grid>
        <Grid item>
          <Input
            className={styles.numberInput}
            value={cutoff.max}
            size="small"
            onChange={(event) => {
              const newCutoff = {
                min: Math.min(Number(event.target.value), cutoff.min),
                max: Math.max(Number(event.target.value), cutoff.min),
              };
              setCutoff(newCutoff);
            }}
            inputProps={{
              min: minDataValue,
              max: maxDataValue,
              type: "number",
            }}
          />
        </Grid>
      </Grid>
      <p>
        {t("text.{{percentage}} of trees are selected", {
          percentage: formatter.format(getAffectedTrees(sortedData, cutoff)),
        })}
      </p>
    </div>
  );
}
