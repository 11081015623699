import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../API/auth";
import { sendVerifyEmail } from "../../../API/verify_email";
import { Splash } from "../../../Layout";
import VerifyEmailForm, { submitVerifyEmailResult } from "./VerifyEmailForm";

const messageMap: { [status: number]: submitVerifyEmailResult } = {
  200: "success",
  422: "invalid",
};

export default function VerifyEmail(): React.ReactNode {
  const auth = useAuth();
  const [message, setMessage] = useState<submitVerifyEmailResult>(null);
  const { i18n } = useTranslation();

  if (auth.username) {
    return <Navigate to="/" />;
  }

  return (
    <Splash>
      <VerifyEmailForm
        onSubmitVerifyEmail={async ({ email }) => {
          if (email.endsWith("@aureaimaging.com")) {
            setMessage("invalid");
            return;
          }
          const response = await sendVerifyEmail(email, i18n.language);
          setMessage(messageMap[response.status] || "error");
        }}
        message={message}
      />
    </Splash>
  );
}
