import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import MapboxDraw, {
  DrawModeChangeEvent,
  MapboxDrawOptions,
} from "@mapbox/mapbox-gl-draw";
import {
  LngLat,
  LngLatLike,
  Point,
  useControl,
  useMap,
} from "react-map-gl/maplibre";
import MapLibre from "maplibre-gl";
import { ControlPosition } from "react-map-gl/src/types/common";
import { Feature, FeatureCollection, MultiPolygon, Polygon } from "geojson";
import calculateArea from "@turf/area";
import calculateCentroid from "@turf/centroid";
import MapControl from "./MapControl";
import styles from "../Field/FieldMap.module.css";
import { useTranslation } from "react-i18next";
import useHistory from "./useHistory";
import "./Draw.css";
import { coordAll, featureEach, kinks } from "@turf/turf";

type Info = {
  area: number;
  valid: boolean;
  centroid: {
    map: LngLat;
    screen: Point;
  };
};

export type DrawRenderControl = FunctionComponent<{
  info: Info | null;
  draw: MapboxDraw;
  save: () => void;
  cancel: () => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
}>;

export type DrawRenderInfo = FunctionComponent<{
  info: Info;
}>;

export type DrawProps = {
  options?: MapboxDrawOptions;
  position?: ControlPosition;
  editPolygon?: Feature<Polygon | MultiPolygon> | null | undefined;
  renderControls?: DrawRenderControl;
  renderInfo?: DrawRenderInfo;
  onSave?: (features: FeatureCollection) => void;
  onCancel?: () => void;
};

function defaultHandler() {
  // do nothing
}

export function isValidPolygon(
  feature: Feature<MultiPolygon | Polygon>,
): boolean {
  return coordAll(feature).length > 3 && !kinks(feature).features.length;
}

export function DrawInfo({
  info,
}: Parameters<DrawRenderInfo>[0]): ReturnType<DrawRenderInfo> {
  const { t } = useTranslation();

  return (
    <div
      className={styles.tooltip}
      style={{
        left: info.centroid.screen.x + "px",
        top: info.centroid.screen.y + "px",
      }}
    >
      {t("text.{{area}}.ha", {
        area: (info.area / 10_000).toFixed(2),
      })}
    </div>
  );
}

export default function Draw({
  options = {},
  editPolygon,
  position = "top-right",
  renderControls = () => null,
  renderInfo = DrawInfo,
  onSave = defaultHandler,
  onCancel = defaultHandler,
}: DrawProps): React.ReactNode {
  const { current: map } = useMap();

  function createInfo(
    collection: FeatureCollection<Polygon | MultiPolygon>,
  ): Info | null {
    if (!map || !collection.features.length) {
      return null;
    }

    const centroid = MapLibre.LngLat.convert(
      calculateCentroid(collection).geometry.coordinates as LngLatLike,
    );
    return {
      area: calculateArea(collection),
      valid: isValidPolygon(collection.features[0]),
      centroid: {
        map: centroid,
        screen: map.project(centroid),
      },
    };
  }

  const [info, setInfo] = useState<Info | null>(
    createInfo({
      type: "FeatureCollection",
      features: editPolygon ? [editPolygon] : [],
    }),
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const draw = useControl<MapboxDraw>(
    () => {
      return new MapboxDraw({
        displayControlsDefault: false,
        styles: defaultTheme,
        keybindings: true,
        userProperties: true,
        ...options,
      });
    },
    { position },
  );

  const history = useHistory<FeatureCollection<Polygon | MultiPolygon>>(
    draw.set,
    {
      type: "FeatureCollection",
      features: editPolygon ? [editPolygon] : [],
    },
  );

  const save = useCallback(() => {
    onSave(draw.getAll());
  }, [draw, onSave]);

  useEffect(() => {
    const onUpdate = () => {
      const allFeatures = draw.getAll() as FeatureCollection<
        Polygon | MultiPolygon
      >;
      setInfo(createInfo(allFeatures));

      featureEach(allFeatures, (feature) => {
        feature.properties = {
          valid: isValidPolygon(feature),
        };
      });
      history.edit(allFeatures);
    };

    const handleModeChange = (event: DrawModeChangeEvent) => {
      if (event.mode !== "draw_polygon" && !draw.getAll().features.length) {
        draw.changeMode("draw_polygon");
      }
    };

    const keyboardHandler = (event: KeyboardEvent) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        draw.trash();
      }
      if (event.key == "Escape") {
        onCancel();
      }
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
        onSave(draw.getAll());
      }
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault();
        history.undo();
      }
      if (event.ctrlKey && event.key === "y") {
        event.preventDefault();
        history.redo();
      }
    };

    if (editPolygon) {
      draw.add(editPolygon);
      // The typescript definition in the library is wrong for the used version of typescript
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      draw.changeMode("direct_select", { featureId: editPolygon.id });
    } else {
      draw.changeMode("draw_polygon");
    }

    map?.on("draw.update", onUpdate);
    map?.on("draw.delete", onUpdate);
    map?.on("draw.create", onUpdate);
    map?.on("draw.modechange", handleModeChange);
    map?.getCanvas().addEventListener("keydown", keyboardHandler);

    return () => {
      map?.off("draw.update", onUpdate);
      map?.off("draw.delete", onUpdate);
      map?.off("draw.create", onUpdate);
      map?.off("draw.modechange", handleModeChange);
      map?.getCanvas().removeEventListener("keydown", keyboardHandler);
    };
  }, [map, draw]);

  return (
    <>
      {info && React.createElement(renderInfo, { info })}
      <MapControl position={position}>
        {React.createElement(renderControls, {
          info,
          draw,
          save,
          undo: history.undo,
          redo: history.redo,
          canRedo: history.canRedo,
          canUndo: history.canUndo,
          cancel: onCancel,
        })}
      </MapControl>
    </>
  );
}

type Theme = typeof MapboxDraw.lib.theme;
type ThemeStyle = Theme[0];

const checkValidityExpression = ["boolean", ["get", "user_valid"], true];
const errorColor = "red";

const themeOverrides: Partial<
  Record<ThemeStyle["id"], Partial<Pick<ThemeStyle, "paint" | "layout">>>
> = {
  "gl-draw-polygon-fill-active": {
    paint: {
      "fill-color": ["case", checkValidityExpression, "#AED7BE", errorColor],
      "fill-outline-color": "transparent",
      "fill-opacity": 0.3,
    },
  },
  "gl-draw-polygon-fill-inactive": {
    paint: {
      "fill-color": ["case", checkValidityExpression, "#AED7BE", errorColor],
      "fill-outline-color": "transparent",
      "fill-opacity": 0.3,
    },
  },
  "gl-draw-polygon-stroke-inactive": {
    paint: {
      "line-color": ["case", checkValidityExpression, "#50BE64", errorColor],
    },
  },
  "gl-draw-polygon-midpoint": {
    paint: {
      "circle-radius": 5,
      "circle-color": "#FFFFFF",
    },
  },
  "gl-draw-polygon-stroke-active": {
    layout: {
      "line-cap": "square",
    },
    paint: {
      "line-width": 2,
      "line-color": ["case", checkValidityExpression, "#fff", errorColor],
      "line-dasharray": [1, 2],
    },
  },
  "gl-draw-line-active": {
    paint: {
      "line-width": 2,
      "line-color": "#fff",
      "line-dasharray": [1, 2],
    },
  },
  "gl-draw-line-inactive": {
    paint: {
      "line-color": "transparent",
    },
  },
  "gl-draw-polygon-and-line-vertex-inactive": {
    paint: {
      "circle-radius": 8,
      "circle-color": "#fff",
    },
  },
  "gl-draw-point-active": {
    paint: {
      "circle-radius": 10,
      "circle-color": "#618C85",
      "circle-stroke-color": "#fff",
      "circle-stroke-width": 2,
    },
  },
  "gl-draw-polygon-fill-static": {
    paint: {
      "fill-color": "#AED7BE",
      "fill-opacity": 0.33,
    },
  },
};

// Check default style: https://github.com/mapbox/mapbox-gl-draw/blob/main/src/lib/theme.js
const defaultTheme = MapboxDraw.lib.theme.map((style) => {
  const override = Object.assign(
    { paint: {}, layout: {} },
    themeOverrides[style.id],
  );

  return {
    id: style.id,
    type: style.type,
    filter: style.filter,
    paint: { ...style.paint, ...override.paint },
    layout: style.layout
      ? { ...style.layout, ...override.layout }
      : { ...override.layout },
  };
});
