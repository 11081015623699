import { RouteObject } from "react-router-dom";
import { extractToken } from "../../../API/ResetPassword/ResetPassword";
import ResetPasswordPage from "./ResetPasswordPage";

const routes: RouteObject = {
  id: "reset-password",
  path: "/reset-password",
  Component: ResetPasswordPage,
  loader: extractToken,
};

export default routes;
