import { get } from "./AureaApi";
import { Params } from "react-router-dom";
import { FeatureCollection, Point } from "geojson";

export type TreePositions = FeatureCollection<Point, unknown>;

export type GetFieldParams = {
  params: Params<"fieldId">;
};

export async function getFieldTrees({
  params: { fieldId },
}: GetFieldParams): Promise<TreePositions> {
  return get<TreePositions>(`/v1/field/${fieldId}/tree/geojson`);
}
