import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  resetPassword,
  ResetPasswordError,
} from "../../../API/ResetPassword/ResetPassword";
import { Splash } from "../../../Layout";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPasswordPage(): React.ReactNode {
  const [message, setError] = useState<Error | null>(null);
  const token = useLoaderData() as string;
  const navigate = useNavigate();

  return (
    <Splash>
      <ResetPasswordForm
        onPasswordReset={async ({ password }) => {
          try {
            await resetPassword(password, token);
            navigate("/");
          } catch (e) {
            if (e instanceof ResetPasswordError) {
              setError(e);
            } else {
              throw e;
            }
          }
        }}
        error={message}
      />
    </Splash>
  );
}
