import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { TextField, TextFieldProps } from "@mui/material";
import { TFunction } from "i18next";

// Redecalare forwardRef
// Thanks: https://fettblog.eu/typescript-react-generic-forward-refs/
declare module "react" {
  function forwardRef<T, P = Record<string, unknown>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

type ValidatedTextFieldProps<V, Context> = TextFieldProps & {
  validate: (
    value: V,
    t: TFunction,
    context: Context,
  ) => string | null | undefined | false;
  defaultValue: V;
  validateContext: Context;
};

export const ValidatedTextField = forwardRef(function ValidatedTextField<
  V,
  Context,
>(
  {
    validate,
    defaultValue,
    validateContext,
    ...rest
  }: ValidatedTextFieldProps<V, Context>,
  forwardedRef: ForwardedRef<HTMLDivElement>,
): React.ReactNode {
  const { t } = useTranslation();
  const [[show, value], setValue] = useState<[boolean, V]>([
    false,
    defaultValue,
  ]);
  const ref = useRef<HTMLInputElement>(null);

  const error = validate(value, t, validateContext);

  useEffect(() => {
    if (ref.current) {
      ref.current.setCustomValidity(error || "");
    }
  }, [error]);

  return (
    <TextField
      ref={forwardedRef}
      inputRef={ref}
      {...rest}
      error={show && !!error}
      helperText={show && error}
      value={value}
      onChange={(ev) => {
        setValue([true, ev.target.value as V]);
      }}
    />
  );
});
