import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FieldProperties } from "../../../../../API/fields";
import DisplayDate from "../../../../../Component/DisplayDate/DisplayDate";
import { ProductName } from "../../../../../Component/Product/ProductName";
import Terms from "../../../../../Component/Terms/Terms";

type ConfirmOrderFormProps = {
  fields: FieldProperties[];
  product: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  comments: string;
  onEdit: string;
  onSubmit: () => void;
};

export default function ConfirmOrderForm({
  fields,
  product,
  dateStart,
  dateEnd,
  comments,
  onEdit,
  onSubmit,
}: ConfirmOrderFormProps): React.ReactNode {
  const { t, i18n } = useTranslation();
  const [acceptTOC, setAcceptTOC] = useState<boolean>(false);
  const [termsOpen, setTermsOpen] = useState<boolean>(false);
  const [errorNotAccepted, setErrorNotAccepted] = useState<boolean>(false);

  const fmt = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const totalArea = fields.reduce((total, field) => total + field.area_ha, 0);
  const closeTerms = () => setTermsOpen(false);

  return (
    <div className="dashboard-page">
      <Dialog fullWidth maxWidth="lg" onClose={closeTerms} open={termsOpen}>
        <DialogTitle>{t("modal.title.General Conditions")}</DialogTitle>
        <DialogContent dividers>
          <Terms />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTerms}>{t("button.Close")}</Button>
        </DialogActions>
      </Dialog>
      <h1>{t("order.title.Review order")}</h1>
      <h3>{t("order.title.Selected Fields", { n: fields.length })}</h3>
      <Grid container spacing={3}>
        {fields.map((field) => (
          <Grid key={field.id} item xs={12} sm={6} md={4} lg={3}>
            <Card variant="outlined">
              <CardContent sx={{ py: 0 }}>
                <h3>{field.name}</h3>
                <p>
                  {field.variety}
                  <br />
                  {t("text.{{area}}.ha", {
                    area: fmt.format(field.area_ha),
                  })}
                </p>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item sm={12}>
          <Typography className="text-caption" variant="body1">
            {t("text.{{area}}.ha", {
              area: fmt.format(totalArea),
            })}
          </Typography>
          <Change onEdit={onEdit} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className="vspace">
            <h3>{t("order.title.Selected product")}</h3>
            <Typography variant="body1">
              <ProductName sku={product} />
            </Typography>
            <Change onEdit={onEdit} />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className="vspace">
            <h3>{t("order.title.Selected date range")}</h3>
            <Typography variant="body1">
              <DisplayDate date={dateStart} />
              –
              <DisplayDate date={dateEnd} />
            </Typography>
            <Change onEdit={onEdit} />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className="vspace">
            <h3>{t("order.title.Comments")}</h3>
            <Typography variant="body1">{comments}</Typography>
            <Change onEdit={onEdit} />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div className="vspace">
            <FormControl error={errorNotAccepted}>
              <FormControlLabel
                control={
                  <Checkbox onChange={(_, checked) => setAcceptTOC(checked)} />
                }
                checked={acceptTOC}
                label={
                  <>
                    {t("label.I agree")}
                    <MuiLink
                      variant="body1"
                      component="span"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setTermsOpen(true);
                      }}
                    >
                      {t("link.Terms and conditions")}
                    </MuiLink>
                  </>
                }
              />
              <FormHelperText>
                {t("message.You must accept the terms and conditions")}
              </FormHelperText>
            </FormControl>
          </div>
          <div className="vspace">
            <Button
              onClick={() => {
                if (acceptTOC) {
                  onSubmit();
                } else {
                  setErrorNotAccepted(true);
                }
              }}
              size="large"
              variant="contained"
              fullWidth
              disableElevation
            >
              {t("button.Submit order")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function Change({
  onEdit,
}: Pick<ConfirmOrderFormProps, "onEdit">): React.ReactNode {
  const { t } = useTranslation();

  return (
    <p>
      <Link to={onEdit}>{t("button.Edit order")}</Link>
    </p>
  );
}
