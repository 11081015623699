import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../Component/Button/Button";
import MessageFlash, {
  MessageLevel,
} from "../../../Component/message-flash/MessageFlash";

export type onResetPassword = (params: { password: string }) => Promise<void>;

type ResetPasswordFormProps = {
  onPasswordReset: onResetPassword;
  error: Error | null;
};

function PasswordResetFlash({
  error,
}: {
  error: Error | null;
}): React.ReactNode {
  const { t } = useTranslation();

  const errorMap = new Map<unknown, null | string>([
    [null, null],
    [undefined, null],
  ]);

  const result = errorMap.get(error?.constructor);
  const msg = result !== undefined ? result : t("unknown.ResetPassword.error");

  return <MessageFlash message={msg} level={MessageLevel.Error} />;
}

export default function ResetPasswordForm({
  onPasswordReset,
  error = null,
}: ResetPasswordFormProps): React.ReactNode {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setRepeatPassword] = useState("");
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("title.ResetPassword")}</h1>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          if (password && passwordConfirmation === password) {
            onPasswordReset({ password });
          }
        }}
      >
        <fieldset className="boxed">
          <div className="vspace">
            <PasswordResetFlash error={error} />
            {passwordConfirmation && passwordConfirmation !== password && (
              <MessageFlash
                message={t("message.Passwords do not match")}
                level={MessageLevel.Error}
              />
            )}
          </div>
          <div className="vspace">
            <TextField
              label={t("label.Password")}
              fullWidth
              required
              variant="outlined"
              value={password}
              type="password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(event.target.value)
              }
            />
          </div>
          <div className="vspace">
            <TextField
              label={t("label.RepeatPassword")}
              fullWidth={true}
              variant="outlined"
              required
              value={passwordConfirmation}
              type="password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRepeatPassword(event.target.value)
              }
            />
          </div>
          <div className="vspace">
            <Button
              type="submit"
              size="large"
              variant="contained"
              fullWidth
              disableElevation
            >
              {t("button.ResetPassword")}
            </Button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
