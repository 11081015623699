import React from "react";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";

function getErrorMessage(error: unknown): string | null {
  if (error instanceof Error) {
    return error.message;
  }
  if (error instanceof Response) {
    return error.statusText;
  }
  return null;
}

export default function ErrorComponent(): React.ReactNode {
  const { t } = useTranslation();
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>{t("heading.Error")}</h1>
      <p>{t("text.An unexpected error occurred")}</p>
      <p>
        <i>{getErrorMessage(error)}</i>
      </p>
    </div>
  );
}
