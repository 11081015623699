import React, { useState } from "react";
import {
  Alert,
  IconButton,
  Slide,
  Snackbar as MuiSnackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AlertColor } from "@mui/material/Alert/Alert";

type SnackbarProps = {
  level: AlertColor;
  message: string;
};

export default function Snackbar({
  level,
  message,
}: SnackbarProps): React.ReactNode {
  const [snackbarOpen, setSnackbar] = useState(true);
  const closeSnackbar = () => setSnackbar(false);
  return (
    <MuiSnackbar
      open={snackbarOpen}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionComponent={Slide}
      autoHideDuration={12000}
      onClose={closeSnackbar}
    >
      <Alert
        sx={{ width: "100%" }}
        severity={level}
        action={
          <IconButton
            color="inherit"
            size="small"
            href=""
            onClick={closeSnackbar}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
