import React from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { FieldsAndResults } from "../../../../../API/fields";
import { submitCustomerOrderLinesWithOrder } from "../../../../../API/orders";
import useOrder from "../useOrder";
import ConfirmOrderForm from "./ConfirmOrderForm";
import { useTranslation } from "react-i18next";

export default function ConfirmOrderPage(): React.ReactNode {
  const order = useOrder();
  const { fields, customer } = useRouteLoaderData(
    "customer",
  ) as FieldsAndResults;
  const navigate = useNavigate();

  const selectedFields = fields.features
    .map((feature) => feature.properties)
    .filter((field) => order.fieldIds.includes(`${field.id}`));
  const { t } = useTranslation();

  return (
    <ConfirmOrderForm
      dateEnd={order.dateEnd}
      dateStart={order.dateStart}
      comments={order.comments}
      fields={selectedFields}
      product={order.product as string}
      onSubmit={async () => {
        await submitCustomerOrderLinesWithOrder(
          customer.id,
          selectedFields.map((field) => {
            return {
              field_id: field.id,
              imagery_params: {
                type: "aurea_drone_flight",
                availability_periods: [
                  {
                    start: `${order.dateStart.toISODate()}T00:00:00Z`,
                    end: `${order.dateEnd.toISODate()}T00:00:00Z`,
                  },
                ],
              },
              order_comments: order.comments,
              product_id: order.product as string,
            };
          }),
        );
        navigate("../../field", {
          state: {
            snackbar: {
              level: "success",
              message: t("message.Your order has been submitted!"),
            },
          },
        });
      }}
      onEdit={"../new"}
    />
  );
}
