import { RouteObject, type LoaderFunction } from "react-router-dom";
import getResultSetLayer from "../../../../API/result_set_layer";
import TaskMapCrumb from "./TaskMapCrumb";
import TaskmapPage from "./TaskmapPage";

const routes: RouteObject = {
  id: "taskmap",
  path: "field/:fieldId/taskmap/:resultSetId",
  loader: getResultSetLayer as LoaderFunction,
  Component: TaskmapPage,
  handle: {
    Crumb: TaskMapCrumb,
  },
  shouldRevalidate: () => false,
};

export default routes;
