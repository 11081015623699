import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import getMachines, { MachineList } from "../../../../API/machines";
import getTerminals from "../../../../API/terminals";
import Button from "../../../../Component/Button/Button";
import TaskMapSlider from "./TaskmapSlider";

import classes from "./TaskmapWidget.module.css";
import { CutoffRange, ApplicationRate } from "./Taskmap";
import TaskmapHistogram from "./TaskmapHistogram";

export type TaskmapWidgetProps = {
  header: string;
  sortedData: number[];
  cutoff: CutoffRange;
  setCutoff: (cutoff: CutoffRange) => void;
  rate: ApplicationRate;
  setRate: (rate: ApplicationRate) => void;
};

export default function TaskmapWidget({
  header,
  sortedData,
  cutoff,
  setCutoff,
  rate,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setRate,
}: TaskmapWidgetProps): React.ReactNode {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [machine, setMachine] = useState<string | undefined>(undefined);
  const [terminal, setTerminal] = useState<string | undefined>(undefined);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Handle Mueller/Sprayer parameter edge-case
  const useSprayParameter =
    terminal === "mueller" && machine === "munckhof_trailed_sprayer";

  const stepContent = [
    // Content for the first step
    <Grid container key={0}>
      <Grid item xs={12} sm={6}>
        {/* Content for the first step */}
        <MachineSelector
          machines={getMachines()}
          machine={machine}
          setMachine={setMachine}
        />
        <TerminalSelector
          terminals={getTerminals()}
          terminal={terminal}
          setTerminal={setTerminal}
        />
        <Button
          variant="contained"
          disabled={!machine || !terminal}
          fullWidth
          onClick={handleNext}
        >
          {t("button.taskmap.continue")}
        </Button>
      </Grid>
    </Grid>,
    // Content for the second step
    <Grid container key={1} alignItems="center">
      <Grid item xs={12} sm={6}>
        <h1>{header}</h1>

        <TaskMapSlider
          cutoff={cutoff}
          setCutoff={setCutoff}
          sortedData={sortedData}
        />
        <input type="hidden" value={cutoff.min} name="cutoff_low" />
        <input type="hidden" value={cutoff.max} name="cutoff_high" />
        <input
          type="hidden"
          value={useSprayParameter ? 0 : rate.low}
          name="rate_low"
        />
        <input
          type="hidden"
          value={useSprayParameter ? 1 : rate.high}
          name="rate_high"
        />
        <input type="hidden" value={machine} name="machine" />
        <input type="hidden" value={terminal} name="terminal" />

        <Button type="submit" variant="contained" disableElevation fullWidth>
          {t("button.generate")}
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TaskmapHistogram
          header={header}
          cutoff={cutoff}
          setCutoff={setCutoff}
          sortedData={sortedData}
        />
      </Grid>
    </Grid>,
  ];

  return (
    <div className={` ${classes.taskmapWidget} ${classes.accordionDetails}`}>
      <h1>{t("heading.Taskmap")}</h1>
      <HorizontalLinearStepper
        stepContent={stepContent}
        activeStep={activeStep}
      />
    </div>
  );
}
export type TerminalSelectorProps = {
  terminals: MachineList;
  terminal: string | undefined;
  setTerminal: (machine: string | undefined) => void;
};

export function TerminalSelector({
  terminals,
  terminal,
  setTerminal,
}: TerminalSelectorProps): React.ReactNode {
  const { t } = useTranslation();
  return (
    <div className={classes.selector}>
      <FormControl fullWidth={true}>
        <InputLabel id="select-terminal-label">
          {t("label.Terminal")}
        </InputLabel>
        <Select
          labelId="select-terminal-label"
          value={terminal}
          name="terminal"
          fullWidth={true}
          onChange={(ev) => setTerminal(ev.target.value)}
        >
          {terminals.map((terminal) => (
            <MenuItem key={terminal.id} value={terminal.id}>
              {t(`terminal.${terminal.id}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
export type MachineSelectorProps = {
  machines: MachineList;
  machine: string | undefined;
  setMachine: (machine: string | undefined) => void;
};

export function MachineSelector({
  machines,
  machine,
  setMachine,
}: MachineSelectorProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className={classes.selector}>
      <FormControl className={classes.selector}>
        <InputLabel id="select-machine-label">{t("label.Machine")}</InputLabel>
        <Select
          labelId="select-machine-label"
          name="machine"
          value={machine}
          fullWidth={true}
          onChange={(ev) => setMachine(ev.target.value)}
        >
          {machines.map((machine) => (
            <MenuItem key={machine.id} value={machine.id}>
              {t(`machine.${machine.id}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export function HorizontalLinearStepper({
  stepContent,
  activeStep,
}: {
  stepContent: React.ReactNode[];
  activeStep: number;
}) {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>{t("label.Select Machine")}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{t("label.Select thresholds")}</StepLabel>
        </Step>
      </Stepper>
      <Box>{stepContent[activeStep]}</Box>
    </Box>
  );
}
