import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link as MuiLink,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, useSearchParams } from "react-router-dom";
import { TFunction } from "i18next";
import Button from "../../../Component/Button/Button";
import Box from "@mui/material/Box";
import { useActionData } from "react-router-dom";
import MessageFlash, {
  MessageLevel,
} from "../../../Component/message-flash/MessageFlash";
import Terms from "../../../Component/Terms/Terms";
import { ValidatedTextField } from "../../../Component/Forms/ValidatedFields";

export function validatePassword(
  value: string,
  t: TFunction,
): string | null | undefined | false {
  if (!value) {
    return t("error. User password is required");
  }
  if ([...value].length < 8) {
    return t("error. User password is too short");
  }
  return undefined;
}

export function validatePasswordMatch(
  passwordRepeat: string,
  t: TFunction,
  validateContext: () => string,
): string | null | undefined | false {
  if (!passwordRepeat) {
    return t("error. User password repeat is required");
  }
  if (passwordRepeat !== validateContext()) {
    return t("error. User password does not match");
  }
  return undefined;
}
export default function RegisterAccountForm() {
  const password = useRef<HTMLDivElement | null>(null);
  const [termsOpen, setTermsOpen] = useState<boolean>(false);
  const closeTerms = () => setTermsOpen(false);

  const { t } = useTranslation();
  const message = (useActionData() as { message: string } | null)?.message;
  const [queryParams] = useSearchParams();
  const token = queryParams.get("token") as string;

  return (
    <div>
      <h1> {t("heading.Register new account")}</h1>
      <Dialog fullWidth maxWidth="lg" onClose={closeTerms} open={termsOpen}>
        <DialogTitle>{t("modal.title.General Conditions")}</DialogTitle>
        <DialogContent dividers>
          <Terms />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTerms}>{t("button.Close")}</Button>
        </DialogActions>
      </Dialog>
      <div className="vspace">
        {message && (
          <MessageFlash message={message} level={MessageLevel.Error} />
        )}
      </div>
      <Form
        action="."
        method="POST"
        encType="application/x-www-form-urlencoded"
      >
        <input type="hidden" value={token} name="token" />
        <fieldset className="boxed">
          <div className="vspace">
            <TextField
              label={t("label.signup.username")}
              name="name"
              fullWidth
              required
            />
          </div>

          <div className="vspace">
            <ValidatedTextField
              ref={password}
              name="password"
              label={t("label.signup.password")}
              fullWidth
              required
              defaultValue=""
              validate={validatePassword}
              validateContext={null}
              type="password"
            />
          </div>

          <div className="vspace">
            <ValidatedTextField
              name="password_repeat"
              label={t("label.signup.password repeat")}
              fullWidth
              required
              defaultValue=""
              validate={validatePasswordMatch}
              validateContext={() => {
                return password.current?.getElementsByTagName("input")[0]
                  .value as string;
              }}
              type="password"
            />
          </div>

          <div className="vspace">
            <TextField
              label={t("label.signup.company name")}
              name="company_name"
              fullWidth
              required
            />
          </div>

          <div className="vspace">
            <FormControl>
              <FormControlLabel
                control={<Checkbox required defaultChecked={false} />}
                defaultChecked={false}
                label={
                  <>
                    {t("label.I agree")}
                    <MuiLink
                      variant="body1"
                      component="span"
                      role="link"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setTermsOpen(true);
                      }}
                    >
                      {t("link.Terms and conditions")}
                    </MuiLink>
                  </>
                }
              />
              <FormHelperText>
                {t("message.You must accept the terms and conditions")}
              </FormHelperText>
            </FormControl>
          </div>

          <div className="vspace">
            <Button
              type="submit"
              size="large"
              variant="contained"
              fullWidth
              disableElevation
            >
              {t("button.signup")}
            </Button>
          </div>

          <div className="vspace">
            <Box sx={{ color: "text.disabled" }}>
              {t("label.Already have an account?")}
            </Box>
            <a href="/login">{t("link.Login to your account")}</a>
          </div>
        </fieldset>
      </Form>
    </div>
  );
}
