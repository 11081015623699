import React, { useState } from "react";
import { FieldFeature } from "../../../../../../Component/Field/FieldMap";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  TextField,
} from "@mui/material";
import Button from "../../../../../../Component/Button/Button";
import styles from "./FieldSelect.module.css";
import { ResultSet } from "../ResultSet/ResultSetSelect";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";

export type FieldResultMap = {
  [fieldId: number]: ResultSet[];
};

/* istanbul ignore next */
function defaultEventHandler(): void {
  // do nothing
}

function filterFields(
  features: FieldFeature[],
  search: string,
  language: string,
): FieldFeature[] {
  return features.filter((feature) => {
    return (
      feature.properties.name
        .toLocaleLowerCase(language)
        .includes(search.toLocaleLowerCase(language)) ||
      feature.properties.crop
        ?.toLocaleLowerCase(language)
        .includes(search.toLocaleLowerCase(language)) ||
      feature.properties.variety
        ?.toLocaleLowerCase(language)
        .includes(search.toLocaleLowerCase(language))
    );
  });
}

export type FieldSelectProps = {
  featureList: FieldFeature[];
  selectedFieldId?: number;
  onSelectField: (selectedField: FieldFeature) => void;
};

export default function FieldSelect({
  featureList,
  selectedFieldId = 0,
  onSelectField = defaultEventHandler,
}: FieldSelectProps) {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [popoverAnchor, setPopoverAnchor] = useState<Element | null>(null);
  const [popoverField, setPopoverField] = useState<number | null>(null);
  const navigate = useNavigate();

  const filteredFeatures = filterFields(featureList, search, i18n.language);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={styles.fieldsListBoxSearch}>
        <h1 className={styles.fieldsListTitle}>{t("heading.Fields")}</h1>
        <TextField
          label={t("label.Search fields")}
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {featureList.length === 0 ? (
        <p className={styles.noFieldsMsg}>{t("text.There are no fields")}</p>
      ) : (
        <List className={styles.fieldList}>
          {filteredFeatures.map((feature) => (
            <ListItemButton
              className={styles.fieldItem}
              key={feature.properties.id}
              onClick={() => onSelectField(feature)}
              selected={feature.properties.id === selectedFieldId}
            >
              <ListItemText>
                <strong className={styles.fieldItemTitle}>
                  {feature.properties.name}
                </strong>
                <span className={styles.fixedWidthArea}>
                  {t("text.{{area}}.ha", {
                    area: feature.properties.area_ha.toFixed(2),
                  })}
                </span>
                <span className={styles.fixedVariety}>
                  {feature.properties.variety}
                </span>
              </ListItemText>
              <IconButton
                aria-label={"Field actions"}
                edge="end"
                onClick={(event) => {
                  setPopoverAnchor(event.currentTarget);
                  setPopoverField(feature.properties.id);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                open={popoverField === feature.properties.id}
                anchorEl={popoverAnchor}
                onClose={() => {
                  setPopoverAnchor(null);
                  setPopoverField(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={(ev) => {
                    ev.stopPropagation();
                    navigate(`${feature.properties.id}/edit`);
                    setPopoverAnchor(null);
                    setPopoverField(null);
                  }}
                >
                  {t("button.Edit field properties")}
                </MenuItem>
                <MenuItem
                  onClick={(ev) => {
                    ev.stopPropagation();
                    navigate(`${feature.properties.id}/edit-extent`);
                    setPopoverAnchor(null);
                    setPopoverField(null);
                  }}
                >
                  {t("button.Change field boundaries")}
                </MenuItem>
              </Menu>
            </ListItemButton>
          ))}
        </List>
      )}
      <div className={styles.fieldsListBoxButton}>
        <Button
          className={styles.drawNewFieldButton}
          fullWidth
          variant="contained"
          disableElevation
          size="large"
          aria-controls="basic-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<KeyboardArrowUpIcon />}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p>{t("button.Add new field")}</p>
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "draw-new-field-button",
          }}
          slotProps={{
            paper: {
              style: {
                width: anchorEl ? anchorEl.clientWidth : "auto",
                transform: "translateY(-10px)", // Add this line to move the menu up by 20px
              },
            },
          }}
          TransitionProps={{
            timeout: 0,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            href="new"
            onClick={() => {
              setAnchorEl(null);
              navigate("new", { relative: "route" });
            }}
          >
            {t("button.Draw new field")}
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
