import { RouteObject } from "react-router-dom";
import forgotPassword from "./forgot-password";
import home from "./home";
import login from "./login";
import resetPassword from "./reset-password";
import verifyEmail from "./verify-email";
import signup from "./signup";

const routes: RouteObject = {
  id: "public",
  children: [home, login, forgotPassword, resetPassword, verifyEmail, signup],
};

export default routes;
