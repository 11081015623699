import { AllGeoJSON, centroid } from "@turf/turf";
import { FeatureCollection, Point } from "geojson";
import React from "react";
import { Layer, LngLatLike, Source } from "react-map-gl/maplibre";
import { useFeatureAction } from "../useLayerEvent";

type TreeCollection = FeatureCollection<Point, unknown>;

export function TreeLayer({
  trees,
}: {
  trees: TreeCollection;
}): React.ReactNode | null {
  if (!trees.features.length) {
    return null;
  }

  const center = centroid(trees as AllGeoJSON);
  useFeatureAction(
    "custom-icon-layer",
    /* istanbul ignore next */ (event) => {
      event.target.flyTo({
        center: center.geometry.coordinates as LngLatLike,
        zoom: 19,
        duration: 500,
      });
    },
  );

  return (
    <>
      <Source data={center} type={"geojson"}>
        <Layer
          maxzoom={19}
          beforeId="tree"
          id="custom-icon-layer"
          type="symbol"
          layout={{
            "icon-image": "tree-icon:custom-tree-icon",
            "icon-size": 1,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          }}
        />
      </Source>
      <Source type="geojson" data={trees}>
        <Layer
          minzoom={19}
          beforeId="tree"
          id="trees-points"
          type="circle"
          paint={{
            "circle-radius": 8,
            "circle-color": getComputedStyle(
              document.documentElement,
            ).getPropertyValue("--tree-normal"),
            "circle-opacity": 1,
            "circle-stroke-width": 1,
            "circle-stroke-color": "rgba(15, 80, 70, 0.75)",
          }}
        />
      </Source>
    </>
  );
}
