import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../API/auth";
import { Splash } from "../../../Layout";
import LoginForm from "./LoginForm";

export default function LoginPage(): React.ReactNode {
  const auth = useAuth();

  if (auth.username) {
    return <Navigate to="/" />;
  }
  return (
    <Splash>
      <LoginForm onLogin={auth.login} error={auth.error} />
    </Splash>
  );
}
