import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../../../Component/Button/Button";
import styles from "./ExportResultModal.module.css";

function downloadFile(file: File) {
  const url = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.download = file.name;
  link.click();
  URL.revokeObjectURL(url);
}
export type ExportResultModalProps = {
  file: File;
};

export default function ExportResultModal({
  file,
}: ExportResultModalProps): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog open={true}>
      <DialogTitle>{t("heading.Export results")}</DialogTitle>
      <DialogActions className={styles.dialogFooter}>
        <Button
          className=""
          fullWidth
          variant="outlined"
          onClick={() => navigate("..")}
          disableElevation
          size="large"
          aria-controls="basic-menu"
          aria-haspopup="true"
        >
          {t("button.Cancel")}
        </Button>
        <Button
          className=""
          fullWidth
          onClick={() => downloadFile(file)}
          variant="contained"
          disableElevation
          size="large"
          aria-controls="basic-menu"
          aria-haspopup="true"
        >
          {t("button.Download")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
