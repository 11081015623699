import React, { useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { Form } from "react-router-dom";
import getTaskMap from "../../../../API/taskmap";
import { BlossomFeatureCollection } from "../../../../Component/Map/Layers/BlossomLayer";
import TaskMap from "./Taskmap";
import TaskmapPopup from "./TaskmapPopup";
import { VigourFeatureCollection } from "../../../../Component/Map/Layers/VigourLayer";
import { BlossomClusterCountFeatureCollection } from "../../../../Component/Map/Layers/BlossomClusterCount";

function getFileNameFromHeader(response: Response): string {
  const header = response.headers.get("Content-Disposition") || "";

  const matches = /filename="([^"]+)/.exec(header);
  if (matches && matches[1]) {
    return matches[1];
  }
  return "taskmap.zip";
}

export default function TaskmapPage(): React.ReactNode {
  const { resultSetId } = useParams();
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [taskmap, setTaskmap] = useState<File | null>(null);

  const postData = async function (form: { [p: string]: string }) {
    setPopupOpen(true);

    const response = await getTaskMap(
      {
        terminal: {
          type: form.terminal,
        },
        parameters: {
          cutoff_low: form.cutoff_low,
          cutoff_high: form.cutoff_high,
          rate_low: Number(form.rate_low),
          rate_high: Number(form.rate_high),
          machine_parameters: {
            type: form.machine,
          },
        },
      },
      resultSetId,
    );

    if (response !== null && !response.ok) {
      throw new Error("Request failed with status " + response.status);
    }
    if (response !== null) {
      setTaskmap(
        new File([await response.blob()], getFileNameFromHeader(response)),
      );
    }
  };

  return (
    <Form
      onSubmit={(event) => {
        postData(
          Object.fromEntries([
            ...new FormData(event.target as HTMLFormElement).entries(),
          ]) as Record<string, string>,
        );
      }}
    >
      <TaskMap
        geojson={
          useLoaderData() as
            | BlossomFeatureCollection
            | BlossomClusterCountFeatureCollection
            | VigourFeatureCollection
        }
      />
      <TaskmapPopup
        taskmap={taskmap}
        open={popupOpen}
        setOpen={(open) => {
          setPopupOpen(open);
          if (!open) {
            setTaskmap(null);
          }
        }}
      ></TaskmapPopup>
    </Form>
  );
}
