import React, { useMemo } from "react";
import {
  Feature,
  FeatureCollection,
  Geometry,
  MultiPolygon,
  Polygon,
} from "geojson";
import { Layer, Source } from "react-map-gl/maplibre";
import { FillLayerSpecification } from "maplibre-gl";
import { Outlet } from "react-router-dom";
import {
  ColorSpecification,
  ExpressionSpecification,
  InterpolationSpecification,
} from "@maplibre/maplibre-gl-style-spec";
import Popup from "../Popup";
import { CutoffRange } from "../../../routes/customer/_customer_id/taskmap/Taskmap";

const NUMBER_OF_COLORS = 8;

export type BlossomFeatureProperties = {
  blossom_area_cm2: number;
  [other: string]: unknown;
};
export type BlossomFeatureCollection = FeatureCollection<
  Polygon | MultiPolygon,
  BlossomFeatureProperties
>;

export type BlossomLayerProps = {
  geojson: BlossomFeatureCollection;
  cutoff?: CutoffRange;
  onHover?: (feature: BlossomFeatureProperties | null) => void;
};

function density(
  feature: Feature<Geometry, BlossomFeatureProperties> | null | undefined,
): number {
  return Number(feature?.properties.blossom_area_cm2);
}
type InterpolationExpression = [
  "interpolate",
  InterpolationSpecification,
  number | ExpressionSpecification,
  ...(number | number[] | ColorSpecification | ExpressionSpecification)[],
];

export default function BlossomLayer({
  geojson,
  cutoff,
}: BlossomLayerProps): React.ReactNode {
  const paint = useMemo(() => {
    const rootStyle = getComputedStyle(document.documentElement);
    const paint: FillLayerSpecification["paint"] = {
      "fill-color": [...Array(NUMBER_OF_COLORS).keys()].reduce(
        (
          prev: InterpolationExpression,
          current: number,
        ): InterpolationExpression => [
          ...prev,
          (current * Math.max(...geojson.features.map(density))) /
            NUMBER_OF_COLORS,
          [
            "to-color",
            rootStyle.getPropertyValue("--color-blossom-map-" + current),
          ],
        ],
        ["interpolate", ["linear"], ["number", ["get", "blossom_area_cm2"]]],
      ),
      "fill-opacity": 0.7,
    };
    return paint;
  }, [geojson]);

  return (
    <>
      <Source id="blossom" type="geojson" data={geojson}>
        <Outlet />
        <Layer
          beforeId="result"
          type="fill"
          id="blossom-fill"
          filter={[
            "all",
            ["!=", ["get", "blossom_area_cm2"], 0],
            [">=", ["get", "blossom_area_cm2"], cutoff ? cutoff.min : 0],
            ["<=", ["get", "blossom_area_cm2"], cutoff ? cutoff.max : 10000],
          ]}
          paint={paint}
        />
        <Popup layer="blossom-fill" component={TreeInformation} />
      </Source>
    </>
  );
}

function TreeInformation({
  properties,
}: {
  properties: BlossomFeatureProperties;
}) {
  return <strong>{Math.round(properties.blossom_area_cm2)}</strong>;
}
