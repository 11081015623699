import { Box, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../Component/Button/Button";

type DownloadTaskmapProps = {
  taskmap: File;
};

export function DownloadTaskmap({
  taskmap,
}: DownloadTaskmapProps): React.ReactNode {
  const { t } = useTranslation();

  const url = URL.createObjectURL(taskmap);
  return (
    <a href={url} download={taskmap.name}>
      {t("link.Download taskmap")}
    </a>
  );
}

export type TaskmapPopupProps = {
  taskmap: File | null;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function TaskmapPopup({
  taskmap,
  open,
  setOpen,
}: TaskmapPopupProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("heading.Taskmap")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {taskmap && <DownloadTaskmap taskmap={taskmap} />}
          {!taskmap && (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t("button.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
