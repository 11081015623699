import React from "react";
import styles from "./Terms.module.css";

export default function Terms(): React.ReactNode {
  return (
    <div className={styles.terms} lang="en">
      <header>
        <h3>Version 2024, Utrecht</h3>
      </header>
      <section className={styles.section}>
        <h2>Definitions</h2>
        In these general conditions (the <dfn>General Conditions</dfn>):
        <ul className={styles.definitions}>
          <li>
            <dfn>Aurea</dfn>
            means Aurea Imaging B.V., a private company with limited liability
            under the laws of the Netherlands with its corporate seat in Utrecht
            and its address at Nijverheidsweg 16, 3534AM Utrecht, the
            Netherlands, registered with the Dutch Trade Register (
            <i lang="nl">Kamer van Koophandel</i>) under number 70573670, also
            known by its trade name Aurea Imaging;
          </li>
          <li>
            <dfn>Client</dfn>
            means any legal entity or individual acting in a professional
            capacity, not being a consumer, that enters or may enter into an
            Agreement with Aurea;
          </li>
          <li>
            <dfn>Parties</dfn>
            means Aurea and the Client both;
          </li>
          <li>
            <dfn>Agreement</dfn>
            means any contract or agreement between Aurea and the Client,
            including concerning the Client’s purchase of one or more Products
            and/or Services;
          </li>
          <li>
            <dfn>Product</dfn>
            means raw data or information collected, processed and/or filtered
            as part of the Agreement, including analysis, combination or
            aggregation thereof, made available to the Client in any form as
            part of the Agreement and may also include hardware products
            provided under the agreement;
          </li>
          <li>
            <dfn>Service</dfn>
            means a service provided by Aurea as part of the Agreement,
            including the development, modification and/or maintenance of
            information technology, with the exception of any Product;
          </li>
          <li>
            <dfn>Person Affiliated with Aurea</dfn>
            means any current or former employee, board member, subsidiary,
            partner or shareholder of Aurea;
          </li>
          <li>
            <dfn>Third Party</dfn>
            means any party not being Aurea, including any contractors and
            subcontractors which Aurea may engage in connection with a Product,
            Service or Agreement;
          </li>
          <li>
            <dfn>written</dfn> and <dfn>in writing</dfn>
            mean by means of a document, letter, fax message or e-mail message
            from an authorized representative of a party to the other party.
          </li>
        </ul>
      </section>
      <section className={styles.section}>
        <h2>Offer and Agreement</h2>
        <p>
          Offers of Aurea are valid for 30 days, unless specified otherwise in
          writing. If the Client’s acceptance of an offer differs from the
          offer, the acceptance cannot lead to a Agreement unless Aurea
          explicitly confirms acceptance of the differing offer in writing.
        </p>
        <p>
          Purchases of a Client of a Product or Service are subject to a written
          Agreement.
        </p>
        <p>
          Any prior or later agreements and/or statements between the Parties,
          which depart from the Agreement or the General Conditions, shall only
          bind Aurea if explicitly confirmed by Aurea in writing.
        </p>
        <p>
          The Client cannot assign, transfer or encumber any of its rights or
          obligations pursuant to the Agreement or General Conditions. Any
          attempt in this respect shall be void, except with the prior written
          explicit consent of Aurea.
        </p>
        <p>
          By entering into the Agreement, the Client accepts that Aurea is
          permitted to instruct a Third Party to carry out work in relation to
          the Agreement, and the Client authorizes Aurea to accept a limitation
          of liability stipulated by that Third Party on behalf of the Client.
        </p>
        <div role="paragraph">
          Aurea is entitled to terminate the Agreement:
          <ol>
            <li>by giving the Client prior written notice of 14 days;</li>
            <li>
              if the Client does not pay an invoice within the applicable
              payment term;
            </li>
            <li>if the Client is in default or breach of contract;</li>
            <li>
              if the Client has failed to comply with an obligation under the
              General Conditions.
            </li>
          </ol>
        </div>
        <p>
          If the Client is liquidated, declared bankrupt or granted suspension
          of payment or files for its own bankruptcy, suspension of payment or
          any other similar regulation, (i) the Client is obliged to inform
          Aurea within 24 hours, (ii) Aurea is entitled to terminate or suspend
          the Agreement immediately or at any moment, and (iii) all outstanding
          payments of that Client to Aurea shall immediately become payable (
          <i lang="nl">onmiddellijk opeisbaar</i>).
        </p>
      </section>
      <section className={styles.section}>
        <h2>Prices</h2>
        <p>
          Prices provided by Aurea exclude VAT which, where applicable, is
          charged at the Dutch rate in euros.
        </p>
        <p>
          Prices provided by Aurea exclude any tariffs that may be levied on
          import/export or trade of goods.
        </p>
        <p>
          Aurea is not held to uphold a price based on a printing or writing
          error.
        </p>
        <p>
          The financial administration of Aurea is leading in determining the
          amount due by the client.
        </p>
        <p>
          The Client shall keep confidential the pricing of Products and
          Services of Aurea.
        </p>
      </section>
      <section className={styles.section}>
        <h2>Payments</h2>
        <p>
          Payments to Aurea must be made within 30 days of the invoice, unless
          specified otherwise by Agreement, by transferring the amount payable
          to the bank account of Aurea stated in the invoice. The Client is
          never permitted to suspend or offset payments.
        </p>
        <p>
          If the Client fails to secure payment within the applicable payment
          term, Aurea is entitled to charge the statutory commercial interest
          rate (<i lang="nl">wettelijke handelsrente</i>) over the outstanding
          amount. Aurea is entitled to refuse a Client’s payment of the
          principal amount, if the interest due and accrued are not paid at the
          same time.
        </p>
      </section>
      <section className={styles.section}>
        <h2>Data and Intellectual Property</h2>
        <p>
          <dfn>Client Data</dfn> means any data made available by the Client to
          Aurea.
        </p>
        <p>
          <dfn>Intellectual Property Rights</dfn> mean any intellectual property
          rights, whether registered, unregistered or pending for application of
          any such protection, including, without limitation, all patents,
          trademarks, copyrights, design rights, database rights, topography
          rights, plant variety rights, trade names, goodwill and all rights in
          any secret process such as trade secrets, business plans or other
          confidential information in respect of a party, its products or its
          services.
        </p>
        <div role="paragraph">
          To the extent that the Client may have or obtain Intellectual Property
          Rights with regard to the Client Data, the Client grants Aurea a
          worldwide right to:
          <ol>
            <li>
              access, store, host, modify, analyze, process, transmit and
              otherwise use Client Data to the extent reasonably required for
              Aurea to provide a Product or Service to the Client, otherwise
              perform the Agreement, and/or comply with any instructions from
              the Client or its representative; and
            </li>
            <li>
              access, store, host, modify, analyze, process, transmit and
              otherwise use aggregate, anonymized data generated from or based
              on Client Data, in order to improve the Products and Services and
              for other business purposes.
            </li>
          </ol>
        </div>
        <p>
          The right granted by the Client to Aurea under Paragraph 5.3,
          Subparagraph i., may also be relied on by a Third Party engaged by
          Aurea to carry out or assist with (part of) the activities listed in
          that limb.
        </p>
        <div role="paragraph">
          To the extent and for the duration necessary under the Agreement,
          Aurea grants the Client a worldwide right to:
          <ol>
            <li>
              access, store, host, modify, analyze, process, transmit and
              otherwise use the Product; and
            </li>
            <li>access, store, and use the Service.</li>
          </ol>
        </div>
        <p>
          Aurea retains all Intellectual Property Rights with regard to its
          Products, Services and all related materials. Aurea shall not transfer
          or assign Intellectual Property Rights to the Client with regard to a
          Product, Service or related materials. The Client shall not obtain
          Intellectual Property Rights to any Product, Service or material,
          regardless of any contribution of the Client in their costs.
        </p>
        <p>
          The Client indemnifies Aurea regarding Intellectual Property Rights of
          the Client and/or a Third Party in materials or information, including
          Client Data, obtained by Aurea from the Client in connection with the
          Agreement.
        </p>
      </section>
      <section className={styles.section}>
        <h2>Warranty</h2>
        <p>
          Aurea offers a limited warranty that the Product (obtained either
          directly via Aurea or via a Reseller) will be free from defects in
          workmanship and materials under normal use for a period of two (2)
          years from the date the Product was purchased and included the initial
          installation, or from the date of purchase until such date as you
          breach any of these Terms and Conditions, whichever is the shorter
          period. During this period, the Product will be – at the sole
          discretion of Aurea – either repaired or replaced by Aurea without
          charge for either parts or labor. Additionally, Aurea will be
          responsible for the transport and/or shipping costs related to the
          repair or replacement. This limited warranty is not transferable.
        </p>
        <div role="paragraph">
          This limited warranty does not apply:
          <ol>
            <li>
              consumable items such as labels or protective layers that are
              subject to natural wear and tear over time, unless the malfunction
              is due to a material or manufacturing defect;
            </li>
            <li>
              cosmetic damage, including but not limited to scratches, dents,
              and broken plastic on ports, unless the malfunction is due to a
              material or manufacturing defect;
            </li>
            <li>
              damage caused by use with a third-party component or product that
              does not comply with Aurea Imaging BV product specifications;
            </li>
            <li>
              damage caused by accident, improper application, improper power
              supply (other than specified), contact with water or other
              liquids, fire, earthquake, or other external causes;
            </li>
            <li>
              damage caused by failure to use the TreeScout product in
              accordance with the user manual, technical specifications, or
              other published guidelines regarding the TreeScout product;
            </li>
            <li>
              damage caused by a repair (including upgrades and expansions)
              performed by anyone other than an Aurea employee or an authorized
              Aurea service provider;
            </li>
            <li>
              for a TreeScout product that has been opened or altered without
              written permission from Aurea to modify the functionality or
              capabilities;
            </li>
            <li>
              defects resulting from normal wear and tear or otherwise
              attributable to the normal aging process of the TreeScout product;
            </li>
            <li>
              if Aurea has received information from the relevant authorities or
              registers that the product has been stolen or if you are unable to
              disable password-protected or other security measures designed to
              prevent unauthorized access to the TreeScout product and cannot
              demonstrate that you are the authorized user of the product (for
              example, by presenting the purchase receipt). To the extent
              permitted by local law, Aurea may provide information about the
              hardware ID of the TreeScout product to such authorities or
              registers for security and fraud prevention purposes.
            </li>
          </ol>
        </div>
        <p>
          In order to make a claim under the limited warranty, you must notify
          Aurea about the defect and explain the defect to Aurea by contacting
          Aurea within the 2-year warranty period via your contact person in
          order to obtain an authorization number for the claim and potential
          return of your order. Aurea will repair the product using new or
          previously used parts that are equivalent to new in performance and
          reliability, or replace with the same model (or with your consent a
          product that has similar functionality).
        </p>
        <p>
          A replacement part or product or a repaired product assumes the
          remaining warranty of the original product or ninety (90) days from
          the date of replacement or repair, whichever provides longer coverage
          for you.
        </p>
      </section>
      <section className={styles.section}>
        <h2>Privacy</h2>
        <p>
          <b>“GDPR”</b> means the General Data Protection Regulation (EU)
          2016/679 and any related European or local applicable regulations in
          relation thereto.
        </p>
        <p>
          The Client ensures that it (i) is compliant with the GDPR at any time,
          and (ii) has obtained the explicit consent of all relevant persons in
          order for Aurea to perform the Agreement.
        </p>
        <p>
          The Client indemnifies Aurea regarding GDPR rights or obligations with
          regard to materials or information, including Client Data, obtained by
          Aurea from the Client in connection with the Agreement.
        </p>
      </section>
      <section className={styles.section}>
        <h2>Liability</h2>
        <p>
          Save for claims arising out of gross negligence (
          <i lang="nl">grove schuld</i>) or willful misconduct (
          <i lang="nl">opzet</i>) on its part, Aurea is not liable towards the
          Client or a Third Party.
        </p>
        <p>
          Claims against Aurea for compensation for damage shall expire if the
          Client fails to inform Aurea in writing of the damage within one month
          after the date on which the Client became aware or should have become
          aware of the damage and Aurea’s liability.
        </p>
        <p>
          If Aurea is liable for any form of damage to persons or property, its
          liability is limited to the amount paid out in that specific case
          under its applicable liability insurance policy plus the applicable
          deductible (<i lang="nl">eigen risico</i>) or, if for any reason this
          insurance policy does not entitle Aurea to any payment, EUR 25,000.
        </p>
        <div role="paragraph">
          Aurea accepts no liability for:
          <ol>
            <li>
              damage caused by any use of incorrect and/or incomplete Client
              Data or other information, resources and facilities provided by
              the Client;
            </li>
            <li>
              damage caused by an error or omission (<i lang="nl">fout</i>) of a
              Third Party instructed by Aurea or the Client to carry out work in
              connection with the Agreement;
            </li>
            <li>
              indirect damage, including consequential losses, loss of income,
              missed savings or losses caused by business stagnation.
            </li>
          </ol>
        </div>
        <p>
          The Client indemnifies Aurea against claims from Third Parties
          regarding damage in connection to a Product or Service used by the
          Client, insofar as Aurea is not liable to the Client in that respect.
          The Client will ensure it has sufficient insurance coverage in this
          respect.
        </p>
        <p>
          Paragraphs 7.1, 7.2, 7.3, 7.4 and 7.5 are irrevocable third-party
          clauses for the benefit of every Person Affiliated with Aurea.
        </p>
        <p>
          The Client shall allow Aurea to attempt to rectify and/or mitigate
          damage incurred by the Client and shall cooperate fully with such
          attempts.
        </p>
      </section>
      <section className={styles.section}>
        <h2>Force Majeure</h2>
        <div role="paragraph">
          <dfn>Force Majeure Event</dfn> means any act, event, or lack thereof
          beyond Aurea’s reasonable control, foreseeable or not, that occurs
          during the performance of the Agreement, which may include, without
          limitation:
          <ol>
            <li>
              strikes, lock-outs or other industrial action at Aurea or a
              supplier of Aurea;
            </li>
            <li>
              civil commotion, riot, invasion, terrorist attack or threat of
              terrorist attack, war (declared or undeclared) or threat or
              preparation for war;
            </li>
            <li>
              storm, fire, explosion, flood, earthquake, subsidence, epidemic
              and other natural disaster;
            </li>
            <li>
              impossibility or severe limitation of the use of railways,
              shipping, aircraft, motor transport, other means of public or
              private transport or public or private telecommunications
              networks;
            </li>
            <li>
              the acts, decrees, legislation, regulations or restrictions of any
              government.
            </li>
          </ol>
        </div>
        <p>
          If Aurea becomes unable to fulfill any of its obligations as a result
          of a Force Majeure Event, the performance of the Agreement is
          suspended for the duration of the Force Majeure Event, without Aurea
          incurring any liability for damage related to that suspension.
        </p>
        <p>
          If the duration of a Force Majeure Event has exceeded three months,
          Aurea is entitled to (partially) dissolve the Agreement without
          incurring any liability for damage related to that dissolution.
        </p>
      </section>
      <section className={styles.section}>
        <h2>Applicability</h2>
        <p>
          The General Conditions are applicable to all offers, quotations,
          Agreements and any other legal relationship between Aurea and the
          Client. The general conditions of the Client or a Third Party, if any,
          do not apply and are rejected.
        </p>
        <p>
          The General Conditions may be relied on by Aurea and every Person
          Affiliated with Aurea.
        </p>
        <p>
          The General Conditions are available under the registration of Aurea
          at the Trade Register (<i lang="nl">Kamer van Koophandel</i>).
        </p>
        <p>
          Aurea is entitled to amend the General Conditions unilaterally. Any
          amendment to this effect shall become effective 14 days after Aurea’s
          written notification of the amendment to the Client.
        </p>
        <p>
          If any provision of the General Conditions or the Agreement becomes
          invalid or is set aside, the other provisions shall remain applicable
          and the Parties shall use their best efforts to agree on replacing the
          provision concerned with a new one, as similar as possible to the
          original provision.
        </p>
      </section>
      <section className={styles.section}>
        <h2>Governing Law</h2>
        <p>
          Dutch law governs the Agreement and the General Conditions, without
          applicability of the United Nations’ Vienna Convention on Agreements
          for the International Sale of Goods.
        </p>
        <p>
          Any civil law dispute or claim in relation to the Agreement or the
          General Conditions, tort included, shall be exclusively resolved in
          first instance by the District Court of Amsterdam, the Netherlands.
        </p>
      </section>
    </div>
  );
}
