import React, { useEffect } from "react";
import {
  useActionData,
  useNavigate,
  useParams,
  useRouteLoaderData,
  SubmitTarget,
} from "react-router-dom";
import { FieldsAndResults } from "../../../../../../API/fields";
import Draw, { DrawRenderControl } from "../../../../../../Component/Map/Draw";
import { useSubmit } from "react-router-dom";
import styles from "../../../../../../Component/Field/FieldOverviewPage.module.css";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../Component/Button/Button";
import { MessageLevel } from "../../../../../../Component/message-flash/MessageFlash";

function EditButtons({ cancel, save, info }: Parameters<DrawRenderControl>[0]) {
  const { t } = useTranslation();

  return (
    <>
      <div className={`${styles.controlPane} ${styles.controlPaneTransparent}`}>
        <Button
          className={styles.buttonCancel}
          type="submit"
          size="large"
          variant="text"
          disableElevation
          onClick={cancel}
        >
          {t("button.Cancel") as string}
        </Button>
        <Button
          type="submit"
          size="large"
          variant="contained"
          disableElevation
          onClick={save}
          disabled={!info || !info.valid}
        >
          {t("button.Save changes") as string}
        </Button>
      </div>
    </>
  );
}

export default function EditExtent(): React.ReactNode | null {
  const { fields } = useRouteLoaderData("customer") as FieldsAndResults;
  const { fieldId, customerId } = useParams();
  const navigate = useNavigate();
  const submit = useSubmit();
  const saveResult = useActionData() as MessageLevel;

  const close = () =>
    navigate(`/customer/${customerId}/field/${fieldId}`, { confirm: false });

  const field = fields.features.find(
    (feature) => `${feature.properties.id}` === fieldId,
  );

  useEffect(() => {
    if (!field || saveResult) {
      close();
    }
  }, [field, saveResult]);

  if (!field || saveResult) {
    return null;
  }

  return (
    <Draw
      onSave={(features) => {
        submit(
          {
            boundary: features.features[0].geometry,
          } as unknown as SubmitTarget,
          {
            method: "patch",
            action: "",
            encType: "application/json",
          },
        );
      }}
      editPolygon={field}
      onCancel={close}
      renderControls={EditButtons}
    />
  );
}
