import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { AllGeoJSON, centroid } from "@turf/turf";
import { DateTime } from "luxon";
import React from "react";
import { Layer, LngLatLike, Source } from "react-map-gl/maplibre";
import { Await } from "react-router-dom";
import { getImage } from "../../../API/AureaApi";
import DisplayDate from "../../DisplayDate/DisplayDate";
import { useLayerEvent } from "../index";
import styles from "./PhotoLayer.module.css";
import { TreePhotoPositions } from "../../../API/drivingDay";

type PhotoLayerProps = {
  photos: TreePhotoPositions;
  active?: Promise<{ url: string; timestamp: DateTime | string }>;
  setActive: (
    active: Promise<{ url: string; timestamp: DateTime | string }> | undefined,
  ) => void;
};

export function PhotoLayer({
  photos,
  active,
  setActive,
}: PhotoLayerProps): React.ReactNode | null {
  const center = centroid(photos as AllGeoJSON);
  useLayerEvent(
    "click",
    "custom-photo-icon-layer",
    /* istanbul ignore next */ (event) => {
      event.target.flyTo({
        center: center.geometry.coordinates as LngLatLike,
        zoom: 19,
        duration: 500,
      });
      event.target.getCanvas().style.cursor = "";
    },
  );
  useLayerEvent(
    "click",
    "photos",
    /* istanbul ignore next */ (event) => {
      const image = event?.features?.at(0)?.properties;
      setActive(
        image
          ? getImage(`/v1/sensor_image/${image.image_id}/webp`).then((url) => ({
              url,
              timestamp: DateTime.fromSQL(image.timestamp),
            }))
          : undefined,
      );
    },
  );
  useLayerEvent(
    "mouseenter",
    "photos",
    /* istanbul ignore next */ (event) => {
      event.target.getCanvas().style.cursor = "pointer";
    },
  );
  useLayerEvent(
    "mouseleave",
    "photos",
    /* istanbul ignore next */ (event) => {
      event.target.getCanvas().style.cursor = "";
    },
  );

  const close = () => setActive(undefined);

  return (
    <>
      {active && (
        <Dialog className={styles.Modal} open={true} onClose={close}>
          <IconButton className={styles.closeBtn} onClick={close}>
            <CloseIcon />
          </IconButton>
          <React.Suspense fallback={<ImageLoading />}>
            <Await resolve={active}>
              {(image) => (
                <>
                  <img className={styles.photo} src={image.url} alt="" />
                  <DialogTitle>
                    <DisplayDate
                      date={image.timestamp}
                      format={DateTime.DATETIME_MED}
                    />
                  </DialogTitle>
                </>
              )}
            </Await>
          </React.Suspense>
        </Dialog>
      )}
      <Source data={center} type={"geojson"}>
        <Layer
          maxzoom={19}
          beforeId="tree-photos"
          id="custom-photo-icon-layer"
          type="symbol"
          layout={{
            "icon-image": "tree-icon:custom-photo-icon",
            "icon-size": 1,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          }}
        />
      </Source>
      <Source type="geojson" data={photos}>
        <Layer
          minzoom={19}
          beforeId="tree-photos"
          id="photos"
          type="symbol"
          layout={{
            "icon-image": "tree-icon:custom-photo-direction-icon",
            "icon-size": 1,
            "icon-rotate": ["+", ["get", "yaw"], ["get", "heading"]],
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "icon-anchor": "bottom",
          }}
        />
      </Source>
    </>
  );
}

function ImageLoading() {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 300, height: 300 }}
    >
      <CircularProgress />
    </Stack>
  );
}
