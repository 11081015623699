import {
  Autocomplete,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { Feature, Geometry } from "geojson";
import booleanIntersects from "@turf/boolean-intersects";
import React from "react";
import { useTranslation } from "react-i18next";
import { Tiling } from "../../../../../../API/fields";
import DisplayDate from "../../../../../../Component/DisplayDate/DisplayDate";
import useDronePhoto from "../../_field_id/useDronePhoto";
import styles from "./TilingSelect.module.css";

export type TilingSelectProps = {
  tilings: Tiling[] | undefined | null;
  bounds: Feature | Geometry;
};

function tilingFilter(
  fieldBoundary: Feature | Geometry,
  tilings: Tiling[] | undefined | null,
): Tiling[] {
  if (!tilings) {
    return [];
  }
  return tilings.filter((tiling) => {
    if (!tiling.extent_polygon) {
      return true;
    }
    return booleanIntersects(tiling.extent_polygon, fieldBoundary);
  });
}

type LayerSelectProps = {
  onActivate: () => void;
  active: boolean;
  children: React.ReactNode;
};
function LayerListItem({ active, onActivate, children }: LayerSelectProps) {
  const { t } = useTranslation();

  return (
    <>
      <ListItem className={styles.resultListItem}>
        <ListItemButton onClick={onActivate}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Checkbox edge="start" disableRipple checked={active} />
          </ListItemIcon>
          <ListItemText primary={t("checkbox.Drone photos")} />
        </ListItemButton>
      </ListItem>
      {active && children}
    </>
  );
}

export default function TilingSelect({ tilings, bounds }: TilingSelectProps) {
  const { t, i18n } = useTranslation();

  const [activePhoto, setActivePhoto] = useDronePhoto();

  const collator = Intl.Collator(i18n.language, { numeric: true });
  const boundedTilings = tilingFilter(bounds, tilings);

  if (!boundedTilings.length) {
    return null;
  }

  return (
    <LayerListItem
      onActivate={() => {
        if (activePhoto) {
          setActivePhoto(null);
        } else {
          setActivePhoto(boundedTilings[0].id.toString());
        }
      }}
      active={!!activePhoto}
    >
      <div className={styles.resultListItemBox}>
        <Autocomplete
          options={boundedTilings.sort((a, b) =>
            collator.compare(a.label, b.label),
          )}
          renderOption={(props, option) => (
            <Option feature={option} {...props} key={option.id} />
          )}
          getOptionLabel={(feature) => feature.label}
          fullWidth={true}
          onChange={(_, feature) => {
            setActivePhoto(feature ? feature.id.toString() : null);
          }}
          renderInput={(params) => (
            <TextField {...params} label={t("label.Tiling")} />
          )}
          value={boundedTilings.find(
            (tiling) => tiling.id.toString() === activePhoto,
          )}
        />
      </div>
    </LayerListItem>
  );
}

type OptionProps = {
  feature: Tiling;
} & React.HTMLAttributes<HTMLLIElement>;

function Option({ feature, ...props }: OptionProps): React.ReactNode {
  return (
    <li {...props}>
      <strong className={styles.label}>{feature.label}</strong>
      <span className={styles.date}>
        <DisplayDate
          date={feature.flight_date ? feature.flight_date : feature.created_at}
        />
      </span>
    </li>
  );
}
