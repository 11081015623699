import React from "react";
import { RouteObject } from "react-router-dom";
import ErrorComponent from "../Component/Error/Error";
import customerRoute from "./customer";
import publicRoute from "./public";
import RootComponent from "./RootComponent";

const routes: RouteObject = {
  Component: RootComponent,
  errorElement: React.createElement(ErrorComponent),
  children: [publicRoute, customerRoute],
};

export default routes;
