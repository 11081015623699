import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Await, useParams, useRouteLoaderData } from "react-router-dom";
import { FieldsAndResults } from "../../../../../../../API/fields";
import { translateProductName } from "../../../../../../../Component/Product/ProductName";
import ExportResultModal from "./ExportResultModal";

export default function DownloadResultSet(): React.ReactNode {
  const { resultSetId } = useParams<{
    resultSetId: string;
    fieldId: string;
  }>();
  const { t } = useTranslation();
  const { fields, resultSets } = useRouteLoaderData(
    "customer",
  ) as FieldsAndResults;

  const selectedResultSet = resultSets.find(
    (resultSet) => resultSet.id === resultSetId,
  );
  const fieldName = fields.features.find(
    (feature) =>
      `${feature.properties.id}` === `${selectedResultSet?.field_id}`,
  )?.properties.name;

  const filename = [
    translateProductName(t, selectedResultSet?.product_sku || ""),
    fieldName,
    selectedResultSet?.detection_dates[0],
  ]
    .filter((component) => component)
    .join("_");
  const results = useRouteLoaderData("resultSet") as {
    geojson: Promise<unknown>;
  };

  return (
    <Suspense fallback={undefined}>
      <Await resolve={results.geojson}>
        {(data) => (
          <ExportResultModal
            file={
              new File([JSON.stringify(data)], `${filename}.geojson`, {
                type: "application/json",
              })
            }
          />
        )}
      </Await>
    </Suspense>
  );
}
